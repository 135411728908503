'use client';


import dynamic from 'next/dynamic'
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import './products.css'
import { useTranslation } from "react-i18next";


const ItemCard = dynamic(() => import('@/components/products/itemCard/ItemCard'), { ssr: false })

  
const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  laptop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 991, min: 640 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 639, min: 360 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};




export default function SemiItemList({products, data}){


    const { t } = useTranslation();
    

  

      return (
        <div className=" py-6 lg:py-12 lg:mt-10 w-full ">
          <div className='flex '>
            <div className=' max-w-2xl mx-8 lg:mx-24  my-8'>
                <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'> {data? data.title : t('products_section.title')} </h2>
                <p className='mt-4 text-lg leading-8 text-gray-600'> {data? data.description : t('products_section.description')} 
                <a href={data? data.linkUrl : "/products"} className="inline-flex items-center font-medium text-red-800  hover:underline">
                {data? data.linkText : t('products_section.link')}
                    <svg className="w-4 h-4 mx-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                    </a>
                </p>
            </div>
            </div>

          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
            className='border-t border-gray-200 ' 
          >

            
            
            {products.map( (card)=>(
              <ItemCard key={card.id} isProduct={true} item={card} view={t('view')}/>
            ))}
            
           

          </Carousel>

          
        </div>
      );
    };