'use client';

import { Carousel, Typography, Button,IconButton } from "@material-tailwind/react";
import Image from "next/image";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


export default function HomeCaru({banners}) {

  const {t} = useTranslation();

    const [stickyClass, setStickyClass] = useState('relative-60');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 110 ? setStickyClass(' xl:top-[55px] ') : setStickyClass('xl:top-[0px]');
    }
  };

  return (
    <Carousel transition={{ duration: 0.1 }}  
    className={` h-[50vh] xl:h-[calc(100vh-60px)] overflow-y-hidden xl:relative  ${stickyClass}`}

    prevArrow={({ handlePrev }) => (
      <IconButton

        aria-label="carousel button prev"
        variant="text"
        color="white"
        size="lg"
        onClick={handlePrev}
        className="!absolute top-2/4 left-4 rounded-full -translate-y-2/4 rtl:hidden"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="pr-1 w-10 h-10">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>

      </IconButton>
    )}
    nextArrow={({ handleNext }) => (
      <IconButton
        aria-label="carousel button next"
        variant="text"
        color="white"
        size="lg"
        onClick={handleNext}
        className="!absolute top-2/4 !right-4 rounded-full -translate-y-2/4 rtl:hidden"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="pl-1 w-10 h-10">
          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>

      </IconButton>
    )}
    
    navigation={({ setActiveIndex, activeIndex, length }) => (
      <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
        {new Array(length).fill("").map((_, i) => (
          <span
            key={i}
            className={`block h-1 cursor-pointer rounded-2xl  transition-all content-[''] ${
              activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
            }`}
            onClick={() => setActiveIndex(i)}
          />
        ))}
      </div>
    )}
    
    >
      {banners.map( ({bannerImage: image, translations}, key) => {
        const data = translations[0]
        return (
      <div key={key} className="relative h-full w-[100vw]">
        
        <Image
          src={process.env.CMS_URL + 'assets/' + image.filename_disk}
          alt={image.description}
          width={960}
          height={540}
          priority={true}
          quality={key===0? 50:100}
          className="h-full w-full object-cover object-bottom"
        />
        <div className={`absolute inset-0 grid h-full w-full ${key ===1? ' place-items-center lg:pl-32 lg:place-items-start lg:items-center': 'place-items-center'} bg-black/60`}>
          <div className={`w-3/4 ${key ===1? 'text-center lg:text-left ': ' text-center '} md:w-2/4`}>
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-xl md:text-4xl lg:text-5xl "
            >
              {data? data.title : t('banner.1.title')}
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 text-sm md:text-lg lg:text-2xl opacity-80"
            >
              {data? data.description : t('banner.1.body')}
             </Typography>
            <div className="flex flex-col lg:flex-row justify-center gap-2">
              <a href={data? data.leftButtonUrl : "/products"} target={data? (data.leftButtonTarget? "_blank": "_self"):"_self"}>
              <Button size="lg" color="white" className=" transition-all text-xs lg:text-sm ease-in-out delay-150 hover:bg-chimib-600 hover:text-white">
                {data? data.leftButtonText : t('banner.1.explore')}
              </Button>
              </a>
              <a href={data? data.rightButtonUrl :"/contact"} target={data? (data.rightButtonTarget? "_blank": "_self"):"_self"}>
              <Button size="lg" color="white" variant="text">
                {data? data.rightButtonText : t('banner.1.link')}
              </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      )})
      }
    </Carousel>
  );
}